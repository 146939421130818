$(document).ready(function(){
    var owl = $('.owl-carousel').owlCarousel({
        center: true,
        loop: true,
        dots: true,
        dotsContainer: '.dots',
        responsiveClass:true,
        responsive:{
            0:{
                items:1
            },
            1000:{
                items:3
            }
        }
    });

    $('#prev').click(function(e) {
        e.preventDefault();
        owl.trigger('prev.owl.carousel');
    });

    $('#next').click(function(e) {
        e.preventDefault();
        owl.trigger('next.owl.carousel');
    });

  });